import React from 'react';

import { ErrorContent, Footer, Header, Menu, SEO } from '../components';

export default function ErrorPage() {
  return (
    <>
      <SEO title="Home" />

      <Header />

      <Menu />

      <ErrorContent />

      <Footer />
    </>
  );
}
